<template>
    <component v-if="authorized" :code="code" :is="SkinComponent" :board="board"></component>
    <v-container v-else>
        <v-card
            tile
            outlined
            elevation="0"
            class="pa-4"
        >
            <v-card-title for="qna-validate" class="justify-center">
                <label for="qna-validate" class="text-center">게시글 열람을 위해 <br class="b-lg-none"/>비밀번호를 입력해주세요.</label>
            </v-card-title>
            <v-card-text class="text-center">
                <div class="input-inline-button">
                    <input v-model="filter.password" maxlength="6" type="password" id="qna-validate" class="input" />
                    <button @click="search" class="button button--primary">확인</button>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    props: {
        code: { type: String, default: null },
        skin: { type: String, default: null },
        _id: String
    },
    data() {
        return {
            authorized: true,
            board: null,

            filter: {
                password: null,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },

        async search() {
            try {
                var { board, authorized } = await api.v1.boards.get({
                    _id: this.$props._id,
                    params: {
                        ...this.filter,
                        password: this.filter.password ? CryptoAES.encrypt(this.filter.password) : undefined,
                    },
                });

                this.board = board;
                this.authorized = authorized;
            } catch (error) {
                this.authorized = error.response.data?.authorized ?? false;
            }
        },
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/view.vue`);
        },
    },
    watch: {
        _board() {
            this.init();
        },
    },
};
</script>
