<template>
    <div class="pagination">
        <a class="pagination__backward" @click="input(1)">
            <v-icon>mdi-chevron-double-left</v-icon>
        </a>
        <a class="pagination__prev" @click="input(page - 1)">
            <v-icon>mdi-chevron-left</v-icon>
        </a>
        <template v-for="i in [-2,-1,0,1,2]" v-if="visibility(page + i)">
            <a v-if="i == 0" class="pagination__current">{{page}}</a>
            <a v-else @click="input(i + page)"> {{page + i}}</a>
        </template>
        <a class="pagination__next" @click="input(page + 1)">
            <v-icon>mdi-chevron-right</v-icon>
        </a>
        <a class="pagination__forward" @click="input(count)">
            <v-icon>mdi-chevron-double-right</v-icon>
        </a>
    </div>
</template>

<script>
export default {
    props : {
        count: { type: Number, default: 0 },
        value: { type: Number, default: 1 },
    },
    data() {
        return {
            page: this.$props.value
        }
    },
    methods : {
        input(page){
            if(0 < page && page <= this.count){
                this.page = page;
                this.$emit("input", page);
                this.$emit("change", page);
            }
        },
        visibility(page){
            if(page < 1) return false;
            else if(this.count < page) return false;
            else return true;
        }
    },
    watch: {
        value(){
            this.page = this.value;
        }
    }
}
</script>

<style lang="scss" scoped>
/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.pagination a{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 34px;
    height: 34px;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 5px;
    -webkit-transition: all ease-out .15s;
    transition: all ease-out .15s;
}
.pagination a:not(.pagination__current) {
    font-weight:400;
    color: #868686;
    cursor: pointer;
}
.pagination__backward, .pagination__prev, .pagination__next, .pagination__forward{
    color: var(--v-primary-base) !important;
}
.pagination__backward .v-icon,
.pagination__prev .v-icon,
.pagination__next .v-icon,
.pagination__forward .v-icon{
    color: inherit;
}
.pagination .pagination__prev{
    margin-right: 16px;
}
.pagination .pagination__next{
    margin-left: 16px;
}
.pagination .pagination__current {
    color: var(--v-primary-base);
    cursor: default;
}
.pagination .pagination__current::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: var(--v-primary-base);
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .pagination{
        margin-top: 70px;
    }
}
</style>